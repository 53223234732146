<h2 class="font-sans text-gray-700 mb-2">
  Import Shipments
</h2>
<form #shipmentImportForm="ngForm" class="font-sans shipment-import flex-col gap-5">
  <div class="w-full sm:flex block gap-x-2 sm:pr-[50%]">
    <mat-card appearance="outlined" fxFlex class="sm:w-1/2">
      <mat-card-header>
        <mat-card-title>Upload Shipment File</mat-card-title>
      </mat-card-header>
      <div class="import-group">
        <mat-card-content>
          <sn-file-upload [required]="true" [accept]="accept" (fileChanged)="onFileInputChange($event)" appearance="outline"></sn-file-upload>
        </mat-card-content>
      </div>
    </mat-card>
    <mat-card appearance="outlined" fxFlex class="sm:w-1/2 font-roboto">
      <mat-card-header>
        <mat-card-title>Directions</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="ml-4 mb-4">
          <ol class="list-decimal">
            <li>
              Download <a class="link" href="https://sastatusnowprdeast.blob.core.windows.net/docs/Shipment_Import_Template.xlsx">this Excel template</a>.<br>Or download a <a class="link" href="https://sastatusnowprdeast.blob.core.windows.net/docs/Shipment_Import_Sample_Data.xlsx">sample file</a> of example data.
            </li>
            <li>Populate the template with your shipment data.</li>
            <li>Upload file.</li>
            <li>You will be notified by email when your file has been processed.</li>
          </ol>
        </div>
        <div class="mb-2">
          <a class="link" href="https://screenpal.com/watch/c0ffYTVavaB" target="_blank"><i class="fa-regular fa-circle-play"></i> Click here to watch a walk through of this process</a>
        </div>
      </mat-card-content>
    </mat-card>
    @if (userInfo?.CustomerId != 1) {
      <mat-card appearance="outlined" fxFlex class="sm:w-1/2 mb-2 sm:mb-0">
        <mat-card-header>
          <mat-card-title>Customer Details</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <table>
            <tr>
              <td class="font-bold p-2">Customer ID:</td>
              <td>{{userInfo?.CustomerId}}</td>
            </tr>
            <tr>
              <td class="font-bold p-2 pr-4">Customer Name:</td>
              <td>{{customer?.name}}</td>
            </tr>
          </table>
        </mat-card-content>
      </mat-card>
    }
  </div>
  @if (displayIsProcessing) {
    <div class="bg-green-50 border-l-4 border-green-700 p-4 my-4">
      <div class="flex basis-0">
        <div class="flex-shrink-0">
          <!-- Heroicon name: solid/exclamation -->
          <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-sm text-green-700">
            <b>Info:</b> Your file is currently being processed and you will be notified by email when complete.
          </p>
        </div>
      </div>
    </div>
  }
  <mat-expansion-panel class="mt-5" [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Template Column Guide
      </mat-panel-title>
    </mat-expansion-panel-header>
    <table mat-table [dataSource]="columnData">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Column Name</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element"> {{element.description}}
          @if (element.name === 'Customer Id' && userInfo?.CustomerId == 1) {
            <div class="mt-4 font-bold">Ops: See <a class="link" target="_blank" href="../admin/customers">customer list</a> for customer Ids</div>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="isRequired">
        <th mat-header-cell *matHeaderCellDef>Required</th>
        <td mat-cell *matCellDef="let element" class="text-center">
          @if (element.isRequired) {
            <i class="fas fa-check"></i>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="requirements">
        <th mat-header-cell *matHeaderCellDef>Requirements</th>
        <td mat-cell *matCellDef="let element"> {{element.requirements}} </td>
      </ng-container>
      <ng-container matColumnDef="exampleValues">
        <th mat-header-cell *matHeaderCellDef>Example Values</th>
        <td mat-cell *matCellDef="let element"> {{element.name === 'Customer Id' ? (userInfo?.CustomerId ?? element.exampleValues) : element.exampleValues}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-expansion-panel>
</form>
