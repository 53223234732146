<div class="divide-y divide-gray-100">
  <div class="flex-none p-3">
    <h2 class="mt-3 font-semibold text-xl text-gray-900 text-center">{{item.title}}</h2>
    <p class="text-sm leading-6 text-gray-700 text-center">
      {{quickView.pc || "No Packaging"}}
    </p>
    <div class="text-center">
      @if (quickView.sc) {
        <span class="inline-flex items-center px-3 text-center py-0.5 rounded-full text-sm font-medium mt-1 bg-blue-100 text-blue-800">
          Stackable
        </span>
      }
      @if (quickView.hz) {
        <span class="inline-flex items-center px-3 text-center py-0.5 rounded-full text-sm font-medium mt-1 bg-green-100 text-green-800">
          Hazardous
        </span>
      }
    </div>
    @if (item.subTitle) {
      <p class="text-sm leading-6 text-gray-700 mt-5">
        <b>Barcode:</b> {{item.subTitle}}
      </p>
    }
    @if (quickView.fc) {
      <p class="text-sm leading-6 text-gray-700">
        <b>Freight Class:</b> {{quickView.fc}}
      </p>
    }
    @if (quickView.nn) {
      <p class="text-sm leading-6 text-gray-700">
        <b>NMFC Number:</b> {{quickView.nn}}
      </p>
    }
    @if (quickView.wg) {
      <p class="text-sm leading-6 text-gray-700">
        <b>Weight:</b> {{quickView.wg}} lbs
      </p>
    }
    @if (quickView.dm) {
      <p class="text-sm leading-6 text-gray-700">
        <b>Dimensions:</b> {{quickView.dm}}
      </p>
    }
  </div>
  <div class="flex flex-auto flex-col p-6 gap-2">
    <button type="button" class="w-full rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" *hasPermission="[permissions.ADMIN_ALL]" (click)="editPackage()">Edit Package</button>
  </div>
</div>