import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, inject, viewChild } from '@angular/core';
import { NgModel, FormsModule } from '@angular/forms';
import { SpinnerService } from '../../core/spinner/spinner.svc';
import { debounceTime, distinctUntilChanged, ObservableInput, of, OperatorFunction, switchMap, tap } from 'rxjs';
import { SearchClient, SearchItemDto, SearchReferenceTypeEnum } from '../../core/services/api.service';
import { RequestService } from '../../core/services/request.service';
import { SnackBarService } from '../../core/services/snack-bar.service';
import { UserQuickViewComponent } from './user-quick-view/user-quick-view.component';
import { LocationQuickViewComponent } from './location-quick-view/location-quick-view.component';
import { PackageQuickViewComponent } from './package-quick-view/package-quick-view.component';
import { ClaimQuickViewComponent } from './claim-quick-view/claim-quick-view.component';
import { ShipmentQuickViewComponent } from './shipment-quick-view/shipment-quick-view.component';

import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'sn-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatFormFieldModule, MatIconModule, MatInputModule, FormsModule, ShipmentQuickViewComponent, ClaimQuickViewComponent, PackageQuickViewComponent, LocationQuickViewComponent, UserQuickViewComponent]
})
export class SearchComponent implements OnInit {
  private _cdr = inject(ChangeDetectorRef);
  private _searchClient = inject(SearchClient);
  private _requestService = inject(RequestService);
  private _spinnerService = inject(SpinnerService);
  private _snackBarService = inject(SnackBarService);

  readonly searchInput = viewChild<NgModel>('searchInput');

  searchTerm: string = "";
  searchResults: SearchItemDto[] = [];
  hoveredResult: SearchItemDto | undefined;
  searchReferenceTypes = SearchReferenceTypeEnum;

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.searchSubscribe();

    // un-comment if working on UI and want to see changes quicker
    //this.searchTerm = "1069309";
    //this.search();
  }

  searchSubscribe() {
    this.searchInput()?.valueChanges?.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      this.switchMapWithOvertakeEvent((searchTerm) => {
        if (searchTerm) {
          this._spinnerService.show();
          return  this._searchClient.search_Search(this.searchTerm);
        }
        return of([]);
      },
      () => {
        this._spinnerService.hide();
      })
    )
    .subscribe((x: SearchItemDto[]) => {
      this._spinnerService.hide();
      this.searchResults = x;
      if (this.searchResults.length > 0) {
        this.hoveredResult = this.searchResults[0];
      }
      this._cdr.detectChanges();
    })
  }

  switchMapWithOvertakeEvent<T, R>(
    project: (value: T, index: number) => ObservableInput<R>,
    onOvertake: (value: T) => void
  ): OperatorFunction<T, R> {
    let awaitingResponse = false;
    return (src$) =>
      src$.pipe(
        tap((v) => {
          if (awaitingResponse) {
            onOvertake(v);
          }
          awaitingResponse = true;
        }),
        switchMap(project),
        tap(() => (awaitingResponse = false))
      );
  }

  get quickViewData() {
    return JSON.parse(this.hoveredResult?.quickActionContent ?? "");
  }
}
