import { NgModule, inject } from '@angular/core';
import { RouterModule } from "@angular/router";
import { CommonModule } from '@angular/common';
import { SpinnerModule } from './spinner/spinner.module';
import { MainNavComponent } from './main-nav/main-nav.component';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SpinnerModule,
        FormsModule,
        MainNavComponent
    ],
    exports: [
        MainNavComponent,
        SpinnerModule
    ],
    providers: []
})

export class CoreModule {
    constructor() {
      const parentModule = inject(CoreModule, { optional: true, skipSelf: true });

      throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
