<div class="font-sans transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all" >
  <mat-form-field class="w-full search-input" appearance="fill">
    <mat-icon matPrefix class="text-gray-300 mx-2">search</mat-icon>
    <input
      matInput
      type="search"
      #searchInput="ngModel"
      [(ngModel)]="searchTerm"
      class="border-0 bg-transparent text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
      placeholder="Search..."
      />
    </mat-form-field>
    @if (searchResults.length > 0) {
      <div class="flex divide-x divide-gray-100">
        <div class="max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 pb-4">
          <h2 class="mb-4 text-xs font-semibold text-gray-500">
            Search Results
          </h2>
          <!-- Results -->
          @for (result of searchResults; track result) {
            <ul class="-mx-2 text-sm text-gray-700" (mouseenter)="hoveredResult = result">
              <li [class.search-hovered]="hoveredResult == result" class="group flex cursor-default select-none items-center rounded-md p-2">
                <span class="ml-3 flex-auto truncate">{{ result.title }}</span>
                <svg [class.hidden]="hoveredResult != result" class="ml-3 h-5 w-5 flex-none text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"clip-rule="evenodd"/>
                </svg>
              </li>
            </ul>
          }
        </div>
        <!-- Quick View -->
        <div class="hidden w-1/2 flex-none flex-col overflow-y-auto sm:flex">
          @switch (hoveredResult.referenceType) {
            @case (searchReferenceTypes.Shipment) {
              <sn-shipment-quick-view [searchItem]="hoveredResult" ></sn-shipment-quick-view>
            }
            @case (searchReferenceTypes.Claim) {
              <sn-claim-quick-view [searchItem]="hoveredResult" ></sn-claim-quick-view>
            }
            @case (searchReferenceTypes.Package) {
              <sn-package-quick-view [searchItem]="hoveredResult" ></sn-package-quick-view>
            }
            @case (searchReferenceTypes.Location) {
              <sn-location-quick-view [searchItem]="hoveredResult" ></sn-location-quick-view>
            }
            @case (searchReferenceTypes.User) {
              <sn-user-quick-view [searchItem]="hoveredResult" ></sn-user-quick-view>
            }
          }
        </div>
      </div>
    }
    @if (searchResults.length == 0) {
      <div class="py-14 px-6 text-center text-sm sm:px-14">
        <i class="fa-duotone fa-magnifying-glass text-xl"></i>
        <p class="mt-4 font-semibold text-lg text-gray-900">Search StatusNow</p>
        <p class="mt-2 text-gray-500">
          Search for Shipments, Locations, Claims and Packages
        </p>
      </div>
    }
  </div>
