import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, EnvironmentProviders, importProvidersFrom, inject, Provider, provideEnvironmentInitializer } from '@angular/core';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { FuseConfig } from '@fuse/services/config';
import { FUSE_CONFIG } from '@fuse/services/config/config.constants';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { fuseLoadingInterceptor, FuseLoadingService } from '@fuse/services/loading';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FusePlatformService } from '@fuse/services/platform';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { FuseUtilsService } from '@fuse/services/utils';

export type FuseProviderConfig = {
    mockApi?: {
        delay?: number;
        services?: any[];
    },
    fuse?: FuseConfig
}

/**
 * Fuse provider
 */
export const provideFuse = (config: FuseProviderConfig): Array<Provider | EnvironmentProviders> =>
{
    // Base providers
    const providers: Array<Provider | EnvironmentProviders> = [
        {
            // Disable 'theme' sanity check
            provide : MATERIAL_SANITY_CHECKS,
            useValue: {
                doctype: true,
                theme  : false,
                version: true,
            },
        },
        {
            provide : FUSE_CONFIG,
            useValue: config?.fuse ?? {},
        },

        // importProvidersFrom(MatDialogModule),
        // {
        //     provide : ENVIRONMENT_INITIALIZER,
        //     useValue: () => inject(FuseConfirmationService),
        //     multi   : true,
        // },

        // provideHttpClient(withInterceptors([fuseLoadingInterceptor])),
        // {
        //     provide : ENVIRONMENT_INITIALIZER,
        //     useValue: () => inject(FuseLoadingService),
        //     multi   : true,
        // },

        provideEnvironmentInitializer(() => inject(FuseMediaWatcherService)),
        provideEnvironmentInitializer(() => inject(FusePlatformService)),
        // {
        //     provide : ENVIRONMENT_INITIALIZER,
        //     useValue: () => inject(FuseSplashScreenService),
        //     multi   : true,
        // },
        provideEnvironmentInitializer(() => inject(FuseUtilsService)),
    ];

    // Return the providers
    return providers;
};
