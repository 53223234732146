import { PermissionCheckerService } from './../services/permission-checker.service';
import { Component, TemplateRef, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, AfterViewInit, Renderer2, HostListener, inject, viewChild } from '@angular/core';
import { UserInfo, UserResolverService } from '../services/user-resolver.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, NavigationEnd, RouterLink, RouterLinkActive } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CustomerSelectorDto, MailboxClient, EmailConversationDto, MailDto, MailViewerDto, NotificationsClient, NotificationTypeEnum, UsersClient } from '../services/api.service';
import { AuthService } from '@auth0/auth0-angular';
import { SpinnerService } from '../spinner/spinner.svc';
import { SnackBarService } from '../services/snack-bar.service';
import { Permissions } from '../../shared/common/permissions';
import { LocationModalComponent } from '../../shared/location-modal/location-modal.component';
import { SearchComponent } from '../../shared/search/search.component';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from '../../../environments/environment';
import { WhatsNewModalComponent } from '../../shared/modals/whats-new-modal/whats-new-modal.component';
import { MatMenuModule } from '@angular/material/menu';
import { NotificationSlideOutComponent } from '../../shared/notification-slide-out/notification-slide-out.component';
import { HasPermissionDirective } from '../../shared/directives/haspermission.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { MailboxService } from 'app/mailbox/mailbox.service';


@Component({
    selector: 'sn-main-nav',
    templateUrl: './main-nav.component.html',
    styleUrls: ['./main-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule, MatTooltipModule, RouterLink, RouterLinkActive, HasPermissionDirective, NotificationSlideOutComponent, MatMenuModule, SearchComponent, MatBadgeModule]
})
export class MainNavComponent implements OnInit, AfterViewInit {
  private _mailboxService = inject(MailboxService);
  private _mailboxClient = inject(MailboxClient);
  private _notificationsClient = inject(NotificationsClient);
  private _userResolverService = inject(UserResolverService);
  private _usersClient = inject(UsersClient);
  private _dialog = inject(MatDialog);
  private _router = inject(Router);
  private _authService = inject(AuthService);
  private _spinnerService = inject(SpinnerService);
  private _snackBarService = inject(SnackBarService);
  private _cdr = inject(ChangeDetectorRef);
  private _renderer = inject(Renderer2);
  protected _permissionCheckerService = inject(PermissionCheckerService);
  private _clipboard = inject(Clipboard);

  readonly filterDialog = viewChild<TemplateRef<any>>('filterDialog');
  readonly searchDialog = viewChild<TemplateRef<any>>('searchDialog');

  showDropdownMenu = false;
  customerName: string = "";
  userInfo: UserInfo | undefined;
  dialogRef: MatDialogRef<any> | undefined;
  hasShipmentEntry: boolean = false;
  loaded: boolean = false;
  showMobileMenu: boolean = false;
  isShipments: boolean = false;
  isCarriers: boolean = false;
  isLocations: boolean = false;
  isAdmin: boolean = false;
  isASMUser: boolean = false;
  permissions = Permissions;

  openMenu: OpenNavMenuEnum | undefined;;
  navMenuEnum = OpenNavMenuEnum;

  @HostListener('window:keydown.control.s', ['$event'])
	searchHotkey(event: KeyboardEvent) {
		event.preventDefault();
		this.showSearch();
	}

  @HostListener('window:keydown.control.shift.b', ['$event'])
	tokenHotkey(event: KeyboardEvent) {
		event.preventDefault();
		this._authService.getAccessTokenSilently().subscribe(x => {
      this._clipboard.copy(x);
      this._snackBarService.open("Bearer Token Copied to Clipboard");
    })
	}

  needsQuoteCount = 0;
  previousMail: EmailConversationDto | undefined;

  constructor() {
      const _router = this._router;
      const _renderer = this._renderer;

      _router
        .events
        .pipe(
          filter(event => event instanceof NavigationEnd)
        ).subscribe((x: any) => {
          this.closeMenu();
          this.setActiveMenuItem();
        });

        _renderer.listen('window', 'click', (e: Event) => {
          if(this.openMenu != null) {
            let target = <any>e.target;
            if(!target.closest("#main-nav")) {
              this.closeMenu();
            }
          }
        });
  }

  public async ngOnInit() {
    this.setActiveMenuItem();

    this._spinnerService.show();

    this._userResolverService.userInfo.subscribe((x: any) => {
      this.isASMUser = x.IsASM;
      this._spinnerService.hide();
      this.userInfo = x;
      this._cdr.detectChanges();
    }, err => {
      this._spinnerService.hide();

      if (err && err.indexOf("Login required") == -1) {
        this._snackBarService.openError("Error getting user information");
      }

      this._cdr.detectChanges();
    });

    const hasSmartInbox = await this._permissionCheckerService.hasPermission(this.permissions.SMARTINBOX_READ);
    if (hasSmartInbox) {
      this._mailboxClient.mailbox_GetLabelCount('Needs Quote').subscribe(res => {
        this.needsQuoteCount = res;
        this._cdr.markForCheck();
      });

      let connection = new HubConnectionBuilder()
        .withUrl(`${environment.backendServerUrl}/hubs/smartinbox`)
        .withAutomaticReconnect()
        .withStatefulReconnect()
        .build();

      connection.on("log", response => console.log(response));
      connection.on('newNeedsQuoteCount', res => this.processNewMailCount(res));
      connection.on('setMailViewer', res => this.processSetMailViewer(res));
      connection.on('removeMailViewer', res => this.processRemoveMailViewer(res));

      connection.on('newUnreadCount', (res: MailDto) => this._mailboxService.newUnreadCount.next(res));
      connection.on('mailCreated', res => this._mailboxService.mailCreated.next(res));
      connection.on('mailUpdated', res => this._mailboxService.mailUpdated.next(res));
      connection.on('mailRead', res => this._mailboxService.mailRead.next(res));

      connection.on('mailShipmentCreated', res => this._mailboxService.mailShipmentCreated.next(res));

      connection.on('mailConversationCreated', (res: EmailConversationDto) => this._mailboxService.mailConversationCreated.next(res));
      connection.on('mailConversationUpdated', (res: EmailConversationDto) => this._mailboxService.mailConversationUpdated.next(res));
      connection.on('mailConversationRead', (res: EmailConversationDto) => this._mailboxService.mailConversationRead.next(res));

      connection.start().then(() => {
        connection.invoke('JoinSmartInboxGroup');
        if (!environment.production) {
          console.log('SmartInbox SignalR connected.');
        }
      });

      connection.onclose(err => {
        const viewer = new MailViewerDto({
          email: this.userInfo.Email,
          fullname: this.userInfo.fullname,
          profilePicture: this.userInfo.profilePicture,
        })
        connection.invoke("UserRemoved", viewer);
      })

      // connection.onreconnecting(err => {
      //   console.log('onreconnecting');
      // })

      // connection.onreconnected(_ => {
      //   console.log('onreconnected')
      // })

      this._mailboxService.userRemoved.subscribe((res: MailViewerDto) => {
        if (!res?.email) {
          return;
        }
        connection.invoke("UserRemoved", res);
      })
    }
  }

  private processNewMailCount(res: any): void {
    if (this.previousMail?.conversationId !== res.conversationId) {
      this.previousMail = res;
      this.needsQuoteCount++;
      this._cdr.markForCheck();
    }
  }

  private processSetMailViewer(res: MailViewerDto): void {
    this._mailboxService.mailViewerSet.next(res);
  }

  private processRemoveMailViewer(res: MailViewerDto): void {
    this._mailboxService.mailViewerRemoved.next(res);
  }

  ngAfterViewInit() {
    this.loaded = true;
  }

  closeMenu() {
    this.openMenu = undefined;
    this.showMobileMenu = false;
    this._cdr.detectChanges();
  }

  setActiveMenuItem() {
    this.isShipments = window.location.pathname.indexOf("/shipments") > -1;
    this.isCarriers = window.location.pathname.indexOf("/carriers") > -1;
    this.isLocations = window.location.pathname.indexOf("/locations") > -1;
    this.isAdmin = window.location.pathname.indexOf("/admin") > -1;
    this._cdr.detectChanges();
  }

  displayFn(customer: CustomerSelectorDto) {
    if (customer) { return customer.name; }
  }

  logout() {
    this._authService.logout({ logoutParams: { returnTo: window.location.origin }});
  }

  onFilterClick(): void {
    this.dialogRef?.close(true);
  }

  toggleMenu(menu: OpenNavMenuEnum) {
    if(this.openMenu == menu) {
      this.openMenu = undefined;
    } else {
      this.openMenu = menu;
    }
  }

  addLocation() {
    this._dialog.open(LocationModalComponent, {disableClose: true});
  }

  showSearch() {
    this._dialog.open(SearchComponent, {
      panelClass: 'search-modal',
      minWidth: '700px',
      position: { top: '75px' }
    });
  }

  resetPassword() {
    const confirmDialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        title: "Confirm Password Reset",
        message: "Are you sure you want to change your password? An email will be sent to <b>" + this.userInfo?.Email + "</b> with a reset link.",
        cancelButtonText: "Cancel",
        displayMessageAsHTML: true,
        okButtonText: "Reset"
      },
      disableClose: true
    });

    confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._spinnerService.show();
        this._usersClient.users_SendPasswordResetCurrentUser().subscribe({
          next: () => this._snackBarService.open("Password reset email sent."),
          error: () => this._snackBarService.open("Password reset failed."),
          complete: () => this._spinnerService.hide()
        })
      }
    });
  }

  showWhatsNewModal(): void {
      this._spinnerService.show();
      this._notificationsClient
          .notifications_GetAllUserNotifications(NotificationTypeEnum.WhatsNew)
          .subscribe(x => {
              if (x?.length > 0) {
                  this._dialog.open(WhatsNewModalComponent, {
                      data: x,
                      minWidth: 500
                  })
              }
          })
          .add(() => this._spinnerService.hide());
  }
}

export enum OpenNavMenuEnum {
  Shipments,
  Carriers,
  Locations,
  Admin
}
