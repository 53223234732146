import { Component, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, inject, input } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerState, SpinnerService } from './spinner.svc';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.cmp.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
        /* Absolute Center Spinner */
        .loading-indicator {
        position: fixed;
        z-index: 11001;
        min-width: 100px;
        min-height: 100px;
        height: 2em;
        width: 2em;
        overflow: show;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        }

        /* Transparent Overlay */
        .loading-indicator:before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        }
    `],
    imports: [MatProgressSpinnerModule]
})
export class SpinnerComponent implements OnDestroy, OnInit {
    private spinnerService = inject(SpinnerService);
    private _cdr = inject(ChangeDetectorRef);

    readonly alwaysOn = input<boolean>(false);

    public visible = false;
    private spinnerStateChanged: Subscription | undefined;

    ngOnInit() {
        if (this.alwaysOn()) {
            this.visible = true;
        }

        this.spinnerStateChanged = this.spinnerService.spinnerState
            .subscribe((state: SpinnerState) => {
                this.visible = state.show;
                this._cdr.detectChanges();
            });
    }

    ngOnDestroy() {
        if (this.spinnerStateChanged) {
            this.spinnerStateChanged.unsubscribe();
        }
    }
}