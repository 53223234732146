import { Component, Input, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { Notification, NotificationsClient } from '../../../core/services/api.service';
import { RequestService } from '../../../core/services/request.service';

@Component({
    selector: 'sn-terms-and-conditions-modal',
    templateUrl: './terms-and-conditions-modal.component.html',
    styleUrls: ['./terms-and-conditions-modal.component.scss'],
    imports: [MatDialogModule]
})
export class TermsAndConditionsModalComponent implements OnInit {
  private _requestService = inject(RequestService);
  private _notificationClient = inject(NotificationsClient);
  protected dialogRef = inject<MatDialogRef<TermsAndConditionsModalComponent>>(MatDialogRef);


  notification!: Notification;

  constructor() {
    const data = inject<Notification>(MAT_DIALOG_DATA);

    this.notification = data;
  }

  ngOnInit(): void {
  }

  acceptTermsConditions(): void {
    this._requestService.save(this._notificationClient.notifications_AcceptTermsAndConditionNotification(this.notification.id), () => {
      this.dialogRef.close();
    }, "Terms and Conditions")
  }
}
