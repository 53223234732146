<div class="divide-y divide-gray-100">
    <div class="flex-none p-3 text-center">
        <h2 class="mt-3 font-semibold text-xl text-gray-900 ">{{item.title}}</h2>
        <p class="text-sm leading-6 text-gray-700">
            {{item.subTitle || "No Customer Name"}}
        </p>
        <p class="text-sm leading-6 text-gray-700">
            {{quickView.em}}
        </p>
    </div>
    <div class="flex flex-auto flex-col p-6 gap-2">
        <button type="button" class="w-full rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" *hasPermission="[permissions.ADMIN_ALL]" (click)="editUser()">Edit User</button>
    </div>
</div>