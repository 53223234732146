import { Component, inject } from '@angular/core';
import { SnackBarService } from '../../../core/services/snack-bar.service';
import { SpinnerService } from '../../../core/spinner/spinner.svc';
import { ConsolidatedDocumentDto, ContactDto, DocumentDto, DocumentsClient, DocumentTypeEnum, EmailDocumentsDto, ReferenceTypeEnum, ShipmentHistoryDto, ShipmentHistoryTypeEnum, ShipmentsClient } from '../../../core/services/api.service';
import { saveAs } from 'file-saver-es';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { UserResolverService } from '../../../core/services/user-resolver.service';
import { RequestService } from '../../../core/services/request.service';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NarikCustomValidatorsModule } from '@narik/custom-validators';
import { MatInputModule } from '@angular/material/input';

import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'sn-shipment-documents-email-modal',
    templateUrl: './shipment-documents-email-modal.component.html',
    styleUrls: ['./shipment-documents-email-modal.component.scss'],
    imports: [FormsModule, MatDialogModule, MatFormFieldModule, MatInputModule, NarikCustomValidatorsModule, MatSelectModule, MatOptionModule, MatSlideToggleModule, MatButtonModule]
})
export class ShipmentDocumentsEmailModalComponent {
  private _documentsClient = inject(DocumentsClient);
  private _spinnerService = inject(SpinnerService);
  private _snackBarService = inject(SnackBarService);
  protected _userResolverService = inject(UserResolverService);
  private _requestService = inject(RequestService);
  private _shipmentsClient = inject(ShipmentsClient);
  private dialogRef = inject<MatDialogRef<ShipmentDocumentsEmailModalComponent>>(MatDialogRef);
  data = inject<{
    emailDto: EmailDocumentsDto;
    emailType: string;
    allContacts: ContactDto[];
    loadNumber: number;
    documents: ConsolidatedDocumentDto[];
}>(MAT_DIALOG_DATA);


  emailDto: EmailDocumentsDto = new EmailDocumentsDto();
  emailType: string;
  allContacts: ContactDto[];
  loadNumber: number;
  isASMUser: boolean = false;
  selectedBOLOption: number = 1;
  DocumentTypeEnum = DocumentTypeEnum;
  documents: ConsolidatedDocumentDto[];

  constructor() {
    const _userResolverService = this._userResolverService;
    const data = this.data;

    _userResolverService.userInfo.subscribe(x => this.isASMUser = x.IsASM);

    this.emailDto = data.emailDto;
    this.emailType = data.emailType;
    this.allContacts = data.allContacts;
    this.loadNumber = data.loadNumber;
    this.documents = data.documents;

    if (this.documents) {
      this.emailDto?.documents?.push(...this.documents.map(doc => {
        return new DocumentDto({
          id: doc.id,
          documentType: doc.documentType,
          documentFormat: doc.documentFormat,
          name: doc.name,
          source: doc.source,
          referenceId: this.loadNumber,
          referenceType: ReferenceTypeEnum.Shipment
        })
      }))
    }

    this.setEmailDefaultValues();
  }

  setEmailDefaultValues() {
    if (!this.emailDto.subject) {
      this.emailDto.subject = `Load #${this.loadNumber} ${this.emailType}`;
    }

    if (!this.emailDto.body) {
      this.emailDto.body = `Attached are your documents for Load #${this.loadNumber}`;
    }
  }

  downloadBOL() {
    this._spinnerService.show();
    this._documentsClient.documents_DownloadBillOfLading(
      this.loadNumber, this.selectedBOLOption, null, null, null, null, null, null).subscribe({
        next: x => {
          this._spinnerService.hide();
          this.saveFile(x.data, this.createFileName("Bill Of Lading", this.loadNumber, "pdf"));
        },
        error: err => {
          console.error(err);
          this._spinnerService.hide();
          this._snackBarService.openError("Error downloading Bill of Lading");
        }
      });
  }

  downloadDocument(id: number, documentName: string, source: string, isBOL: boolean) {
    if (isBOL) {
      return this.downloadBOL();
    }

    this._documentsClient.documents_DownloadDocument(id, this.loadNumber).subscribe({
      next: (x) => {
        this._spinnerService.hide();
        this.saveFile(x.data, documentName);
      },
      error: (err) => {
        console.error(err);
        this._spinnerService.hide();
        this._snackBarService.openError("Error downloading documents");
      }
    });
  }

  createFileName(componentName: string, loadNumber: number, extension: string) {
    return componentName + " " + loadNumber + "." + extension;
  }

  saveFile = (blobContent: Blob, fileName: string, type: string = "application/octet-stream") => {
    const blob = new Blob([blobContent], { type: type });
    saveAs(blob, fileName);
  };

  email() {
    if (this.emailType === "Bill of Lading") {
      this._requestService.execute(this._documentsClient
        .documents_EmailBillOfLading(this.emailDto, this.selectedBOLOption, null, null, null, null, null, null), x => {
          this._shipmentsClient.shipments_AddShipmentHistory(<ShipmentHistoryDto>({
            description: "Email Bill of Lading",
            historyType: ShipmentHistoryTypeEnum.EmailSent,
            shipmentId: this.loadNumber
          }), this.loadNumber);

          this.dialogRef.close(true);
        }, "Document Email Sent", "Error Sending Document Email");
    } else {
      this._requestService.execute(this._documentsClient.documents_EmailDocuments(this.emailDto), x => {
        this.dialogRef.close(true);
      }, "Document Email Sent", "Error Sending Document Email");
    }
  }
}
