import { Component, Input, OnInit, Renderer2, inject } from "@angular/core";
import {
  BillOfLadingDocumentType,
  ConsolidatedDocumentDto,
  ContactsClient,
  DocumentDto,
  DocumentFormatTypeEnum,
  DocumentsClient,
  DocumentTypeEnum,
  EmailDocumentsDto,
  PickupResponseDto,
  ReferenceTypeEnum,
  ServiceTypeEnum,
  ShipmentQuoteDto,
  ShipmentsClient,
  TripStatusEnum,
} from "../../../core/services/api.service";
import { LabelPrintService } from "../../../core/services/labelprint.service";
import { RequestService } from "../../../core/services/request.service";
import { SpinnerService } from "../../../core/spinner/spinner.svc";
import { ShipmentDocumentsEmailModalComponent } from "../../../shared/shipment-documents/shipment-documents-email-modal/shipment-documents-email-modal.component";
import { saveAs } from "file-saver-es";
import { SnackBarService } from "../../../core/services/snack-bar.service";
import { MatDialog } from "@angular/material/dialog";
import { ShipmentProgressBarComponent } from "../../../shared/widgets/shipment-progress-bar/shipment-progress-bar.component";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { forkJoin, Observable, pipe, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";


@Component({
    selector: "sn-quote-scheduled",
    templateUrl: "./quote-scheduled.component.html",
    styleUrls: ["./quote-scheduled.component.scss"],
    imports: [RouterLink, ShipmentProgressBarComponent]
})
export class QuoteScheduledComponent implements OnInit {
  private _labelPrintService = inject(LabelPrintService);
  private _documentsClient = inject(DocumentsClient);
  private _requestService = inject(RequestService);
  private _spinnerService = inject(SpinnerService);
  private _snackBarService = inject(SnackBarService);
  private _matDialog = inject(MatDialog);
  private _contactsClient = inject(ContactsClient);
  private _shipmentClient = inject(ShipmentsClient);
  private _route = inject(ActivatedRoute);
  private _httpClient = inject(HttpClient);
  private _renderer = inject(Renderer2);

  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() model: ShipmentQuoteDto | undefined;

  serviceTypes = ServiceTypeEnum;
  hasPrinter: boolean = false;
  showMenu: boolean = false;
  apiLabels: ConsolidatedDocumentDto[] = [];
  apiAllLabel: ConsolidatedDocumentDto | undefined;
  stateAllLabel: ConsolidatedDocumentDto | undefined;
  stateLabels: ConsolidatedDocumentDto[] = [];
  loadNumber: number;

  constructor() {
    const _renderer = this._renderer;

    _renderer.listen("window", "click", (e: Event) => {
      if (this.showMenu) {
        let target = <any>e.target;
        if (
          !target.closest("#option-menu-div") &&
          !target.closest("#bol-menu")
        ) {
          this.showMenu = false;
        }
      }
    });
  }

  ngOnInit(): void {
    // Alternatively, subscribe to state changes for better practice
    if (window.history.state.ltlResponse) {
      let response = window.history.state.ltlResponse as PickupResponseDto;
      this.stateAllLabel = response.labels.find((x) => x.name && x.name.indexOf("all-") > -1);
      this.stateLabels = response.labels.filter((x) => x.name && x.name.indexOf("all-") == -1);
    }

    this._route.paramMap.subscribe(params => {
      this.loadNumber = Number(params.get('id'));

      if (this.loadNumber) {
        this._spinnerService.show();

        forkJoin({
          labels: this.loadLabels(),
          shipment: this._shipmentClient.shipments_GetQuote(this.loadNumber)
        }).subscribe({
          next: (x) => {
            this.model = x.shipment;
            this._spinnerService.hide();
            this.checkForPrinter();
          }
        });
      }
    });
  }

  loadLabels(): Observable<ConsolidatedDocumentDto[]> {
    return this._documentsClient
      .documents_GetDocuments(
        this.loadNumber,
        ReferenceTypeEnum.Shipment,
        DocumentTypeEnum.Label,
        true
      )
      .pipe(
        tap((x) => {
          this.apiAllLabel = x.find((x) => x.name && x.name.indexOf("all-") > -1);
          this.apiLabels = x.filter(
            (x) =>
              x.documentFormat != DocumentFormatTypeEnum.Other &&
              x.documentFormat != DocumentFormatTypeEnum.Pdf
          );
          console.log('loadLabels', this.apiLabels);
          console.log('loadLabels', this.apiAllLabel);
        })
      );
  }

  checkForPrinter() {
    this._labelPrintService.hasPrinter().subscribe((x) => {
      this.hasPrinter = x;
    });
  }

  printLabels() {
    if (this.model) {
      // refresh labels once more to ensure all labels are loaded
      this.loadLabels().subscribe((x) => {
        this._labelPrintService.printLabels(this.model.id, this.apiLabels);
      });
    }
  }

  printShipmentLabel() {
    if (this.model) {
      this._requestService.get(
        this._documentsClient.documents_GetShipmentLabel(this.model.id),
        (x) => {
          this._labelPrintService.printZpl(x);
        },
        "Shipment Label"
      );
    }
  }

  downloadLabels() {
    if (this.model) {
      if (this.apiAllLabel || this.stateAllLabel) {
        let label = this.stateAllLabel ?? this.apiAllLabel;
        this._httpClient.get(label.location, { responseType: 'blob' }).subscribe(x => {
          this.saveFile(x, label.name);
        });
      } else {
        let labels = this.stateLabels.length > 0 ? this.stateLabels : this.apiLabels;
        labels.push(this.stateAllLabel ?? this.apiAllLabel);

        this._requestService.get(
          this._documentsClient.documents_DownloadDocumentsByUrl(
            labels.map(x => x.location),
            this.model.id
          ),
          (x) => {
            this.saveFile(x.data, `${this.model?.id}_Labels.zip`);
          },
          "Labels"
        );
      }
    }
  }

  downloadBOL() {
    if (this.model) {
      this._spinnerService.show();

      this._documentsClient.documents_DownloadBillOfLading(
        this.model.id,
        BillOfLadingDocumentType.Standard,
        null, null, null, null, null, null
      ).subscribe({
        next: (x) => {
          this._spinnerService.hide();
          if (this.model) {
            this.saveFile(
              x.data,
              this.createFileName("Bill Of Lading", this.model.id, "pdf")
            );
          }
        },
        error: (err) => {
          console.error(err);
          this._spinnerService.hide();
          this._snackBarService.openError("Error downloading Bill of Lading");
        }
      });
    }
  }

  saveFile = (
    blobContent: Blob,
    fileName: string,
    type: string = "application/octet-stream"
  ) => {
    const blob = new Blob([blobContent], { type: type });
    saveAs(blob, fileName);
  };

  showEmailModal(isBol: boolean = false) {
    let emailDto = new EmailDocumentsDto();
    const emailType = isBol ? "Bill of Lading" : "Documents";
    emailDto.loadNumber = this.model?.id ?? 0;
    emailDto.to = this.model?.originLocationContact?.email;
    emailDto.documents = [];

    // Consolidated Documents
    let documents = [] as any;

    if (isBol) {
      if (this.model) {
        // Attached BOL to emailDto.documents
        let bolDocument = new DocumentDto();
        bolDocument.name = this.createFileName(
          "Bill Of Lading",
          this.model.id,
          "pdf"
        );
        bolDocument.documentType = DocumentTypeEnum.BillOfLading;
        bolDocument.documentFormat = DocumentFormatTypeEnum.Pdf;
        emailDto.documents.push(bolDocument);
      }
    } else {
      if (this.apiAllLabel) {
        documents.push(this.apiAllLabel);
      } else if (this.apiLabels) {
        documents = this.apiLabels;
      }
    }

    if (this.model) {
      this._requestService.get(
        this._contactsClient.contacts_GetCarrierContacts(this.model.id),
        (x) => {
          this._matDialog.open(ShipmentDocumentsEmailModalComponent, {
            data: {
              id: 0,
              emailType: emailType,
              emailDto: emailDto,
              allContacts: x,
              loadNumber: this.model?.id,
              documents: documents,
            },
            disableClose: true,
          });

          this.showMenu = false;
        },
        "Contacts"
      );
    }
  }

  createFileName(componentName: string, loadNumber: number, extension: string) {
    return componentName + " " + loadNumber + "." + extension;
  }
}
