.inbound-shipments-view {
    .status {
        font-size: 12px;
    }

    .status i {
        font-size: 18px;
        margin-left: 5px;
    }

    .status i.fa-check {
        color: green;
    }

    .status i.fa-clock {
        color: rgb(28, 43, 177);
    }

    .status i.fa-shipping-fast, .status i.fa-warehouse {
        color: #FF6633;
    }

    .status i.fa-ban {
        color: #ff3333;
    }

    .status i span {
        font-family: Roboto, "Helvetica Neue", sans-serif;
        font-weight: 300;
        font-size: 18px;
        vertical-align: middle;
        padding-left: 5px;
    }

    @media (max-width: 1024px) {
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
        .inbound-shipments-items .mat-mdc-checkbox .mat-checkbox-frame {
            transform: scale(1.5) !important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
        .inbound-shipments-items .mat-mdc-checkbox .mat-checkbox-background {
            transform: scale(1.5) !important;
        }
    }
}

.mat-button-focus-overlay {
  background-color: #000;
}

.mat-button-ripple.mat-ripple,
.mat-button-focus-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  border-radius: inherit;
}

.mat-button .mat-button-focus-overlay,
.mat-icon-button .mat-button-focus-overlay {
  opacity: 0;
}

.mat-button-focus-overlay {
  opacity: 0;
}

.mat-calendar-controls .mat-icon-button:hover .mat-button-focus-overlay,
.mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
  opacity: 0.04;
}

.mat-icon-button {
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;
  border-radius: 50%;
}

.mat-button {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
}
