<form #emailForm="ngForm">
  <h2 mat-dialog-title>Email {{emailType}}</h2>
  <mat-dialog-content>
    <mat-form-field style="width: 100%">
      <mat-label>To</mat-label>
      @if (emailType != 'Rate Confirmation') {
        <input matInput name="to" [(ngModel)]="emailDto.to" type="email"
          ngvemail required />
      }
      @if (emailType == 'Rate Confirmation') {
        <mat-select name="to" [(ngModel)]="emailDto.to" required>
          @for (contact of allContacts; track contact) {
            <mat-option [value]="contact.email">{{contact.contactName}}
            </mat-option>
          }
        </mat-select>
      }
    </mat-form-field>
    @if (isASMUser) {
      <mat-form-field style="width: 100%">
        <mat-label>CC</mat-label>
        <mat-select name="cc" [(ngModel)]="emailDto.cc">
          <mat-option>None</mat-option>
          <mat-option value="dispatch@asmgroupinc.com">Dispatch</mat-option>
          <mat-option value="orderdesk@asmgroupinc.com">Order Desk</mat-option>
          <mat-option value="support@asmgroupinc.com">Support</mat-option>
          <mat-option value="trailers@asmgroupinc.com">Trailers</mat-option>
        </mat-select>
      </mat-form-field>
    }
    <mat-form-field style="width: 100%">
      <mat-label>Subject</mat-label>
      <input name="subject" matInput [(ngModel)]="emailDto.subject" required />
    </mat-form-field>
    <mat-form-field style="width: 100%">
      <mat-label>Body</mat-label>
      <textarea name="body" matInput [(ngModel)]="emailDto.body" required></textarea>
    </mat-form-field>

    <div>Attachments:</div>
    <ul>
      @for (doc of emailDto.documents; track doc) {
        <li><a class="link"
        (click)="downloadDocument(doc.id, doc.name, doc.source, doc.documentType == DocumentTypeEnum.BillOfLading)">{{doc.name}}</a></li>
      }
    </ul>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
    <button (click)="email()" mat-raised-button color="primary" [disabled]="!emailForm.valid">Email</button>
  </mat-dialog-actions>
</form>
