import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { HostListener, Component, OnInit, inject } from '@angular/core';
import { Notification, NotificationPriorityEnum } from '../../core/services/api.service';
import { UserResolverService } from '../../core/services/user-resolver.service';
import { RequestService } from '../../core/services/request.service';
import { DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'sn-notification-details-modal',
    templateUrl: './notification-details-modal.component.html',
    imports: [MatDialogModule, MatButtonModule, DatePipe]
})
export class NotificationDetailsModalComponent implements OnInit {
  data = inject(MAT_DIALOG_DATA);
  dialogRef = inject<MatDialogRef<NotificationDetailsModalComponent>>(MatDialogRef);
  requestService = inject(RequestService);
  matDialog = inject(MatDialog);
  protected _userResolverService = inject(UserResolverService);


  public notification: Notification | undefined;
  public notificationPriorityEnum = NotificationPriorityEnum;

  ngOnInit(): void {
    if (this.data) {
      this.notification = this.data.notification;
    }
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }
}
