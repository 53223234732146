import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PackagesClient, SearchItemDto } from '../../../core/services/api.service';
import { RequestService } from '../../../core/services/request.service';
import { UserResolverService } from '../../../core/services/user-resolver.service';
import { PackageAddEditModalComponent } from '../../../packages/package-add-edit-modal/package-add-edit-modal.component';
import { Permissions } from '../../../shared/common/permissions';
import { HasPermissionDirective } from '../../directives/haspermission.directive';

@Component({
    selector: 'sn-package-quick-view',
    templateUrl: './package-quick-view.component.html',
    styleUrls: ['./package-quick-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [HasPermissionDirective]
})
export class PackageQuickViewComponent implements OnInit {
  private _matDialog = inject(MatDialog);
  private _userResolverService = inject(UserResolverService);
  private _packagesClient = inject(PackagesClient);
  private _requestService = inject(RequestService);

  get searchItem(): SearchItemDto | undefined{
    return this.item;
  }
  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() set searchItem(value: SearchItemDto | undefined) {
      this.item = value;
      this.quickView = JSON.parse(value?.quickActionContent ?? "");
  }

  isASMUser: boolean = false;
  item: SearchItemDto | undefined;
  quickView: any;
  permissions = Permissions;

  ngOnInit(): void {
    this._userResolverService.userInfo.subscribe(x => this.isASMUser = x.IsASM);
  }

  editPackage() {
    this._requestService.get(this._packagesClient.packages_Get(Number(this.searchItem?.referenceId)), x => {
      this._matDialog.open(PackageAddEditModalComponent, {
        data: {...x},
        minWidth: '850px',
        disableClose: true
      });
    }, "Package");

  }
}
