import { Component, OnInit, inject } from '@angular/core';
import { VersionCheckService } from './core/services/version-check.service';
import { AuthService, User } from '@auth0/auth0-angular';
import { NotificationsClient, NotificationTypeEnum } from './core/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { WhatsNewModalComponent } from './shared/modals/whats-new-modal/whats-new-modal.component';
import { Router, RouterOutlet } from '@angular/router';
import { TermsAndConditionsModalComponent } from './shared/modals/terms-and-conditions-modal/terms-and-conditions-modal.component';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { Notification } from './core/services/api.service';
import { SpinnerComponent } from './core/spinner/spinner.cmp';
import { MainNavComponent } from './core/main-nav/main-nav.component';
import { AngularPlugin, IErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { environment } from '../environments/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { UserResolverService } from './core/services/user-resolver.service';

declare var CobrowseIO: any;

@Component({
    selector: 'status-now',
    templateUrl: `./app.component.html`,
    imports: [MainNavComponent, RouterOutlet, SpinnerComponent],
    providers: [VersionCheckService]
})

export class AppComponent implements OnInit {
  private versionCheckService = inject(VersionCheckService);
  private authService = inject(AuthService);
  private userResolverService = inject(UserResolverService);
  private notificationsClient = inject(NotificationsClient);
  private router = inject(Router);
  private dialog = inject(MatDialog);

  authenticated: boolean = false;

  constructor() {
      const versionCheckService = this.versionCheckService;

      versionCheckService.initVersionCheck('./version.json');
    }

  ngOnInit() {
    this.authService.isAuthenticated$.subscribe(async x => {
      this.authenticated = x;

      if(this.authenticated) {
        await this.getNotifications();

        this.authService.user$.subscribe(user => {
          if(user) {
            this.addCobrowse(user);
            this.addAppInsights(user);
          }
        });
      }
    });
  }

  async getNotifications() {
    var termsConditions = await firstValueFrom(this.notificationsClient.notifications_GetUserTermsAndConditionNotifications());
    var notifications = await firstValueFrom(this.notificationsClient.notifications_GetUserNotifications(NotificationTypeEnum.WhatsNew));

    if (termsConditions) {
      this.showTermsConditionsNotification(termsConditions).then(() => {
        this.showNotifications(notifications);
      });
    }
    else {
      this.showNotifications(notifications);
    }
  }

  showNotifications(notifications: Notification[]) {
    if(notifications?.length > 0) {
      this.dialog.open(WhatsNewModalComponent, {
        data: notifications,
        minWidth: 400,
      })
    }
  }

  showTermsConditionsNotification(termsConditions: Notification) : Promise<any> {
    if (termsConditions) {
      var dialog = this.dialog.open(TermsAndConditionsModalComponent, {
        data: termsConditions,
        minWidth: 400,
        disableClose: true
      })

      return lastValueFrom(dialog.afterClosed());
    }

    return Promise.resolve();
  }

  addCobrowse(user: User) {
    CobrowseIO.license = 'ZJo7uwZW-FVb9w';
    CobrowseIO.customData = {
      email: user.email,
      device_name: user.name
    };
    CobrowseIO.start();
  }

  addAppInsights(user: User) {
		var angularPlugin = new AngularPlugin();

		const appInsights = new ApplicationInsights({
			config: {
				connectionString: environment.appInsightsConnectionString,
				extensions: [<any>angularPlugin],
				extensionConfig: {
					[angularPlugin.identifier]: {
						router: this.router,
						errorServices: [
							new CustomErrorHandler(this.userResolverService),
						],
					},
				},
			},
		});

		appInsights.loadAppInsights();

		if (user?.email) {
			appInsights.setAuthenticatedUserContext(
				user.email,
				user.email,
				false
			);
		}
	}
}

export class CustomErrorHandler implements IErrorService {
  isASMUser: boolean = false;

  constructor(userResolverService: UserResolverService) {
    userResolverService.userInfo.subscribe(x => {
      if (x.IsASM) {
        this.isASMUser = x.IsASM;
      }
    });
  }

  handleError(error: any) {
    if (error && (!environment.production || this.isASMUser)) {
      console.error(error);
    }
  }
}
