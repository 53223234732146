import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SearchItemDto } from '../../../core/services/api.service';
import { UserResolverService } from '../../../core/services/user-resolver.service';
import { UserModalComponent } from '../../../admin/users/add-edit/user-modal.component';
import { Permissions } from '../../../shared/common/permissions';
import { HasPermissionDirective } from '../../directives/haspermission.directive';

@Component({
    selector: 'sn-user-quick-view',
    templateUrl: './user-quick-view.component.html',
    styleUrls: ['./user-quick-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [HasPermissionDirective]
})
export class UserQuickViewComponent implements OnInit {
  private _matDialog = inject(MatDialog);
  private _userResolverService = inject(UserResolverService);

  get searchItem(): SearchItemDto | undefined {
    return this.item;
  }
  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() set searchItem(value: SearchItemDto | undefined) {
      this.item = value;
      this.quickView = JSON.parse(value?.quickActionContent ?? "");
  }

  isASMUser: boolean = false;
  item: SearchItemDto | undefined;
  quickView: any;
  permissions = Permissions;

  ngOnInit(): void {
    this._userResolverService.userInfo.subscribe(x => this.isASMUser = x.IsASM);
  }

  editUser() {
    const dialogRef = this._matDialog.open(UserModalComponent, {
      width: '450px',
      data: { email: this.item?.referenceId },
      disableClose: true
    });
  }
}
