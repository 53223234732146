import { Injectable, inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SpinnerService } from '../spinner/spinner.svc';
import { SnackBarService } from './snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  private _snackBarService = inject(SnackBarService);
  private _spinnerService = inject(SpinnerService);


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return <any>next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          this._spinnerService.hideAll();
          var apiError = error;

          if(!error?.error?.text) {
            this.showGenericError(apiError);
            return;
          }

          error.error.text().then((x: string) => {
              try {
                var result = JSON.parse(x);

                if(!result.byPassGlobalHandler) {
                  if(result.message) {
                    this._snackBarService.open(result.message);
                  } else {
                    throw new Error();
                  }
                }
              } catch (error) {
                this.showGenericError(apiError);
                return;
              }
          });

          return (throwError(error) as any);
        })
    );
  }

  private showGenericError(apiError: any) {
    this._snackBarService.open(apiError.message);

    return throwError(apiError);
  }
}
