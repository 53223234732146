<main class="max-w-2xl mx-auto pt-8 pb-24 sm:pt-16 sm:px-6 lg:max-w-5xl lg:px-8">
  <div class="px-4 space-y-2 sm:px-0 sm:flex sm:items-baseline sm:justify-between sm:space-y-0">
    <div class="flex sm:items-baseline sm:space-x-4">
      <h1 class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Shipment #{{loadNumber}}</h1>
      <a [routerLink]="['/shipments', loadNumber]"
        class="hidden text-sm font-medium text-blue-600 hover:text-blue-500 sm:block">View details<span
          aria-hidden="true"> &rarr;</span></a>
    </div>
  </div>
  @if (model) {
  <section class="mt-6">
    <div class="space-y-8">
      <div class="bg-white border-t border-b border-gray-200 shadow-sm sm:border sm:rounded-lg">
        <div class="py-6 px-4 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
          <div class="sm:flex lg:col-span-6">
            <div class="flex flex-col">
              <div class="text-lg font-medium text-gray-900">Carrier Service</div>
              <div>{{model.selectedQuote.serviceDescription}}</div>
              <div class="z-0 inline-flex rounded-md mt-2">
                @if (model.serviceTypeID == serviceTypes.LTL) {
                <button (click)="downloadBOL()" type="button"
                  class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-blue-600 text-sm font-medium text-white hover:bg-blue-500 focus:z-10 focus:outline-none">Download
                  Bill of Lading</button>
                }
                @if (model.serviceTypeID == serviceTypes.SmallParcel && hasPrinter) {
                <button (click)="printLabels()" type="button"
                  class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-blue-600 text-sm font-medium text-white hover:bg-blue-500 focus:z-10 focus:outline-none">Print
                  Labels</button>
                }
                @if (model.serviceTypeID == serviceTypes.SmallParcel && !hasPrinter) {
                <button (click)="downloadLabels()" type="button"
                  class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-blue-600 text-sm font-medium text-white hover:bg-blue-500 focus:z-10 focus:outline-none">Download
                  Labels</button>
                }
                <div id="option-menu-div" class="-ml-px relative block">
                  <button type="button" (click)="showMenu = !showMenu"
                    class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-blue-600 text-sm font-medium text-white hover:bg-blue-500 focus:z-10 focus:outline-none"
                    id="option-menu-button" aria-expanded="true" aria-haspopup="true">
                    <span class="sr-only">Open options</span>
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                      aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                  </button>
                  @if (showMenu) {
                  <div id="menu"
                    class="origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu" aria-orientation="vertical" aria-labelledby="option-menu-button" tabindex="-1">
                    @if (model.serviceTypeID == serviceTypes.LTL && hasPrinter) {
                    <a (click)="printShipmentLabel()"
                      class="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-100 hover:text-gray-90"
                      role="menuitem" tabindex="-1">Print Shipment Label</a>
                    }
                    @if (model.serviceTypeID == serviceTypes.SmallParcel && hasPrinter) {
                    <a (click)="downloadLabels()"
                      class="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-100 hover:text-gray-90"
                      role="menuitem" tabindex="-1">Download Labels</a>
                    }
                    @if (model.serviceTypeID == serviceTypes.SmallParcel) {
                    <a (click)="showEmailModal()"
                      class="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem" tabindex="-1"> Email Labels </a>
                    }
                    @if (model.serviceTypeID == serviceTypes.LTL) {
                    <a (click)="showEmailModal(true)"
                      class="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem" tabindex="-1"> Email Bill of Lading </a>
                    }
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div class="mt-6 lg:mt-0 lg:col-span-6">
            <dl class="grid grid-cols-2 gap-x-6 text-sm">
              <div>
                <dt class="font-medium text-gray-900">Pickup</dt>
                <dd class="mt-3 text-gray-500">
                  <span class="block">{{model.originLocationContact.location.address1}}</span>
                  @if (model.originLocationContact.location.address2) {
                  <span class="block">{{model.originLocationContact.location.address2}}</span>
                  }
                  <span class="block">{{model.originLocationContact.location.city}},
                    {{model.originLocationContact.location.state}}
                    {{model.originLocationContact.location.postalCode}}</span>
                  <span class="block">{{model.originLocationContact.location.countryName}}</span>
                </dd>
              </div>
              <div>
                <dt class="font-medium text-gray-900">Delivery</dt>
                <dd class="mt-3 text-gray-500">
                  <span class="block">{{model.destinationLocationContact.location.address1}}</span>
                  @if (model.destinationLocationContact.location.address2) {
                  <span class="block">{{model.destinationLocationContact.location.address2}}</span>
                  }
                  <span class="block">{{model.destinationLocationContact.location.city}},
                    {{model.destinationLocationContact.location.state}}
                    {{model.destinationLocationContact.location.postalCode}}</span>
                  <span class="block">{{model.destinationLocationContact.location.countryName}}</span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <sn-shipment-progress-bar [scheduledOnDate]="model.scheduledOnDate"
          [scheduledPickupDate]="model.scheduledPickupDate"
          [scheduledDeliveryDate]="model.scheduledDeliveryDate"></sn-shipment-progress-bar>
        <button type="button"
          class="w-full items-center px-4 py-2 border border-transparent text-sm font-medium rounded-b-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          routerLink="/shipments/new">Add Another</button>
      </div>
    </div>
  </section>
  }
  <!-- Billing -->
  @if (false) {
  <section aria-labelledby="summary-heading" class="mt-16">
    <h2 id="summary-heading" class="sr-only">Billing Summary</h2>
    <div class="bg-gray-100 py-6 px-4 sm:px-6 sm:rounded-lg lg:px-8 lg:py-8 lg:grid lg:grid-cols-12 lg:gap-x-8">
      <dl class="grid grid-cols-2 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:col-span-7">
        <div>
          <dt class="font-medium text-gray-900">Billing address</dt>
          <dd class="mt-3 text-gray-500">
            <span class="block">Floyd Miles</span>
            <span class="block">7363 Cynthia Pass</span>
            <span class="block">Toronto, ON N3Y 4H8</span>
          </dd>
        </div>
        <div>
          <dt class="font-medium text-gray-900">Payment information</dt>
          <dd class="-ml-4 -mt-1 flex flex-wrap">
            <div class="ml-4 mt-4 flex-shrink-0">
              <svg aria-hidden="true" width="36" height="24" viewBox="0 0 36 24" xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-auto">
                <rect width="36" height="24" rx="4" fill="#224DBA" />
                <path
                  d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
                  fill="#fff" />
              </svg>
              <p class="sr-only">Visa</p>
            </div>
            <div class="ml-4 mt-4">
              <p class="text-gray-900">Ending with 4242</p>
              <p class="text-gray-600">Expires 02 / 24</p>
            </div>
          </dd>
        </div>
      </dl>
      <dl class="mt-8 divide-y divide-gray-200 text-sm lg:mt-0 lg:col-span-5">
        <div class="pb-4 flex items-center justify-between">
          <dt class="text-gray-600">Subtotal</dt>
          <dd class="font-medium text-gray-900">$72</dd>
        </div>
        <div class="py-4 flex items-center justify-between">
          <dt class="text-gray-600">Shipping</dt>
          <dd class="font-medium text-gray-900">$5</dd>
        </div>
        <div class="py-4 flex items-center justify-between">
          <dt class="text-gray-600">Tax</dt>
          <dd class="font-medium text-gray-900">$6.16</dd>
        </div>
        <div class="pt-4 flex items-center justify-between">
          <dt class="font-medium text-gray-900">Order total</dt>
          <dd class="font-medium text-indigo-600">$83.16</dd>
        </div>
      </dl>
    </div>
  </section>
  }
</main>